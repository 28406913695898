$color-1: #aeb4c1;
$color-2: #373e51;
$color-3: #b97f5a;
$color-4: #80848e;
$color-5: #a0a3b3;
$color-6: #141c2c;
$color-7: #7e5036;
$color-8: #656a7c;
$color-9: #caced2;

.no-recipe-selected {
  @media (min-width: 890px) {
    background-repeat: no-repeat;
    background-position: 90% 70%;
    background-size: 500px 500px;
    background-image: url('https://static-sabores-caseros.nyc3.digitaloceanspaces.com/web-assets/chef.svg');
  }
}

.default-image {
  height: 250px;
  width: 250px;
  top: -39px !important;
}

.image-recipe {
  position: absolute;
  right: 50px;
  top: -20px;

  @media (max-width: 1130px) {
    display: none;
  }
}

.custom-image {
  border-radius: 50%;
  height: 200px;
  width: 200px;
}

.default-text {
  @media (max-width: 890px) {
    .title, p {
      text-align: center;
    }
  }

}

.recipe-card {
  @media (min-width: 640px) {
    padding-left: 2rem;
  }

  .recipe-search {
    .ant-select-selector {
      background-color: $color-8 !important;
      border: none !important;
      font-weight: bold !important;
      color: $color-6 !important;
    }

  }

  .ingredients-card {
    position: relative;
    min-height: 640px;

    @media (min-width: 768px) {
      margin-top: 14px;

    }

    .ingredients-specs {
      color: black !important;
      display: flex;
      margin-top: 30px;
      align-items: center;
      font-weight: 500 !important;
    }

    .footer {
      position: absolute;
      bottom: 10px;
      right: 10px;
      border: none;
    }
  }
}

.badgeNotification {
  background-color: #FF6B72;
  border-radius: 20px;
  padding: 4px;
  color: white;
}

.green-btn-bg {
  background-color: #389e0d;
  color: white;
}